import React, { useState, useCallback } from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import FaturasPendentes from './FaturasPendentes';


const FaturasAbertas = () => {
    const [updateKey, setUpdateKey] = useState(0);
    document.title="Faturas Abertas | Painel Líder Fibra";

    const handleButtonClick = useCallback(async () => {
        setUpdateKey(prevKey => prevKey + 1);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Faturas em Aberto" pageTitle="Financeiro" />
                    <FaturasPendentes onTransactionSuccess={handleButtonClick} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaturasAbertas;
