import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import axios from "axios";

import { Link } from "react-router-dom";

import logoDark from "../../assets/images/logo-dark.png";

import withRouter from "../../Components/Common/withRouter";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const errorsEmail = "E-mail inválido ou vazio.";
  const errorsPassword = "Senha inválida ou vazia.";

  useEffect(() => {
    const authUser = sessionStorage.getItem("authUser");
    const loginTime = sessionStorage.getItem("loginTime");

    if (authUser && loginTime) {
      const currentTime = new Date();
      const sessionTime = new Date(loginTime);

      if (currentTime.getTime() - sessionTime.getTime() <= 60 * 60 * 1000) {
        window.location.href = "/dashboard";
      }
    }
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const val = event.target.value;
    setEmail(val);
    if (!validateEmail(val)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
  };

  const handlePasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (val === "") {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/guest/login`,
        {
          email,
          password,
        }
      );

      if (response.data && response.data.token) {
        const fullName = response.data.name.split(" ");
        const firstName = fullName[0];
        const lastName = fullName[fullName.length - 1];

        const authUser = {
          status: "success",
          token: response.data.token,
          data: {
            _id: response.data.id,
            first_name: firstName,
            last_name: lastName,
            email: response.data.email,
            setores: response.data.setores,
            role: response.data.role,
          },
        };

        // Armazenar o authUser na sessão
        sessionStorage.setItem("authUser", JSON.stringify(authUser));
        response.data.role === "admin"
          ? (window.location.href = "/usuarios")
          : (window.location.href = "/clientes");
      } else {
        setErrorMsg(
          response.message ? response.message : "Ocorreu um erro desconhecido."
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Ocorreu um erro desconhecido:" + error.message);
      }
    }
    setLoading(false);
  };

  document.title = "Login | Painel Líder";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoDark} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Bem-Vindo!</h5>
                      <p className="text-muted">
                        Faça o login para entrar no painel.
                      </p>
                    </div>
                    {errorMsg && <Alert color="danger"> {errorMsg} </Alert>}
                    <div className="p-2 mt-4">
                      <Form onSubmit={handleSubmit} action="#">
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            E-mail
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Digite aqui"
                            type="email"
                            onChange={handleEmailChange}
                            onBlur={handleEmailChange}
                            value={email}
                            invalid={emailInvalid}
                          />
                          {emailInvalid && (
                            <FormFeedback type="invalid">
                              {errorsEmail}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Esqueceu a senha?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Senha
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={password}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Digite aqui"
                              onChange={handlePasswordChange}
                              onBlur={handlePasswordChange}
                              invalid={passwordInvalid}
                            />
                            {passwordInvalid && (
                              <FormFeedback type="invalid">
                                {errorsPassword}
                              </FormFeedback>
                            )}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={loading}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? "Carregando..." : "Entrar"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
