import React, { useState, useCallback, useEffect } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import FeatherIcon from "feather-icons-react";

const TabEndereco = ({ enderecos, setEnderecos, loading, setLoading }) => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const editarEnderecos = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/clientes-enderecos`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          enderecos: enderecos,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const apagar = async (e, index) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/clientes-enderecos/${index}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        setEnderecos(enderecos.filter((item, i) => i !== index));
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const handleCepBlur = async (item) => {
    try {
      const _cep = item.cep.replace(/[^0-9]/, "");
      const response = await axios.get(
        `https://viacep.com.br/ws/${_cep}/json`
      );
      item.endereco = response.logradouro;
      item.bairro = response.bairro;
      item.cidade = response.localidade;
      item.estado = response.uf;
    } catch (error) {
      console.error(error);
    }
    return item;
  };

  return (
    <React.Fragment>
      <Form>
        <h5 style={{ padding: "20px 10px" }}>Endereços</h5>
        {enderecos.map((enderecoItem, index) => (
          <Row key={index}>
            <Col lg={4}>
              <div className="mb-3">
                <Label className="form-label">CEP</Label>
                <InputMask
                  mask="99999-999"
                  placeholder="00000-000"
                  value={enderecoItem.cep}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].cep = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                  onBlur={() => {
                    let newEnderecos = [...enderecos];
                    setEnderecos(handleCepBlur(newEnderecos));
                  }}
                >
                  {(inputProps) => (
                    <Input
                      type="text"
                      className="form-control"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </div>
            </Col>
            <Col lg={8}>
              <div className="mb-3">
                <Label className="form-label">Endereço</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Rua, Avenida, etc..."
                  value={enderecoItem.endereco}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].endereco = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label className="form-label">Número</Label>
                <Input
                  type="number"
                  className="form-control"
                  placeholder="000"
                  maxLength={7}
                  value={enderecoItem.numero}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].numero = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label className="form-label">Complemento</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Casa, Apartamento, etc..."
                  maxLength={255}
                  value={enderecoItem.complemento}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].complemento = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label className="form-label">Bairro</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Bairro"
                  maxLength={255}
                  value={enderecoItem.bairro}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].bairro = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Cidade</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Cidade"
                  maxLength={255}
                  value={enderecoItem.cidade}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].cidade = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Estado</Label>
                <select
                  className="form-select mb-3"
                  value={enderecoItem.estado}
                  onChange={(e) => {
                    let newEnderecos = [...enderecos];
                    newEnderecos[index].estado = e.target.value;
                    setEnderecos(newEnderecos);
                  }}
                >
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={(e) => apagar(e, enderecoItem.id)}
                  title="Apagar Endereço"
                >
                  <FeatherIcon icon="trash-2" height={15} />
                </button>
              </div>
            </Col>
          </Row>
        ))}

        <Row>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-start">
              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={(e) => editarEnderecos(e)}
              >
                Editar Todos
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default TabEndereco;
