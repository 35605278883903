import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import axios from "axios";

//import images
import progileBg from "../../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../../assets/images/users/multi-user.jpg";

import TabPerfil from "./TabPerfil";
import TabEnderecos from "./TabEnderecos";
import TabProdutos from "./TabProdutos";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [createdAt, setCreatedAt] = useState("");
  const [tipoCliente, setTipoCliente] = useState("cpf");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [dadosCliente, setDadosCliente] = useState([]);
  const [revendedor, setRevendedor] = useState(1);
  const [enderecos, setEnderecos] = useState([]);
  const [enderecoCobranca, setEnderecoCobranca] = useState(0);
  const [status, setStatus] = useState("ativo");
  const [dataNascimento, setDataNascimento] = useState("");
  const [vencimento, setVencimento] = useState("10");
  const [formaPagamento, setFormaPagamento] = useState("boleto");
  const [clienteId, setCLienteId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [revendas, setRevendas] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dateFormat = (date) => {
    let d = new Date(date),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const cliente_id = urlParams.get("cliente_id");

    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
      if (response.sucesso && response.sucesso === 1) {
        setRevendas(response.data);

        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clientes/${cliente_id}`
        );
        if (response.sucesso && response.sucesso === 1) {
          setTipoCliente(
            response.data.cliente.codigo.includes("PF") ? "cpf" : "cnpj"
          );
          setCLienteId(response.data.user.id);
          setCreatedAt(dateFormat(response.data.user.created_at));
          setNome(response.data.user.name);
          setEmail(response.data.user.email);
          setRevendedor(response.data.cliente.revendedor);
          setDataNascimento(formatDate(response.data.cliente.data_nascimento));
          setVencimento(response.data.cliente.vencimento);
          setFormaPagamento(response.data.cliente.forma_pagamento);
          setEnderecos(response.data.enderecos);
          setDadosCliente(response.data.dados);
          setStatus(response.data.cliente.status);
          setEnderecoCobranca(response.data.cliente.endereco_cobranca);
        } else {
          toast.error(response.mensagem);
        }
      } else {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  document.title = "Cliente | Painel Líder Fibra";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={avatar1}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                        />
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">{nome}</h5>
                    <p className="text-muted mb-0">Cliente desde {createdAt}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Dados Cadastrais
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Endereços
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          tabChange("3");
                        }}
                        type="button"
                      >
                        <i className="far fa-envelope"></i>
                        Produtos
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <TabPerfil
                        tipoCliente={tipoCliente}
                        status={status}
                        setStatus={setStatus}
                        enderecoCobranca={enderecoCobranca}
                        setEnderecoCobranca={setEnderecoCobranca}
                        enderecos={enderecos}
                        nome={nome}
                        setNome={setNome}
                        email={email}
                        setEmail={setEmail}
                        dadosCliente={dadosCliente}
                        setDadosCliente={setDadosCliente}
                        revendedor={revendedor}
                        setRevendedor={setRevendedor}
                        dataNascimento={dataNascimento}
                        setDataNascimento={setDataNascimento}
                        vencimento={vencimento}
                        setVencimento={setVencimento}
                        formaPagamento={formaPagamento}
                        setFormaPagamento={setFormaPagamento}
                        loading={loading}
                        setLoading={setLoading}
                        revendas={revendas}
                        setRevendas={setRevendas}
                        clienteId={clienteId}
                      />
                    </TabPane>

                    <TabPane tabId="2">
                      <TabEnderecos
                        enderecos={enderecos}
                        setEnderecos={setEnderecos}
                        loading={loading}
                        setLoading={setLoading}
                        clienteId={clienteId}
                        />
                    </TabPane>

                    <TabPane tabId="3">
                      <TabProdutos 
                        loading={loading}
                        setLoading={setLoading}
                        activeTab={activeTab}
                        clienteId={clienteId}
                        revendedor={revendedor}
                        />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
