import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

const Clientes = () => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState([]);

  const [cliente, setCliente] = useState([]);

  // Delete cliente
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  // Delete Data
  const onClickDelete = (cliente) => {
    setCliente(cliente);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteCustomer = async () => {
    if (cliente) {
      setLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/clientes/${
            cliente.user.status === "ativo" ? "desativar" : "ativar"
          }/${cliente.user.id}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          fetchUsers();
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API");
        console.log(error);
      }
      setLoading(false);
      setDeleteModal(false);
    }
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes`
      );

      if (response.sucesso && response.sucesso === 1) {
        setClientes(response.data);
        console.log(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".clienteCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = async () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach(async (element) => {
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/clientes/desativar/${element.value}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          console.log(`Cliente ID ${element.value} desativado com sucesso!`);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
      toast.success("Clientes desativados com sucesso!");
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;

    fetchUsers();
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".clienteCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="clienteCheckBox form-check-input"
              value={cellProps.row.original.user.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "",
        accessor: "user.id",
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: "Código",
        accessor: "cliente.codigo",
        filterable: true,
        Cell: (cell) => {
          return <b>{cell.value}</b>;
        },
      },
      {
        Header: "Nome",
        accessor: "user.name",
        filterable: true,
        Cell: (cell) => cell.value,
      },
      {
        Header: "Revendedor",
        accessor: "cliente.revendedor_dados.name",
        filterable: true,
        Cell: (cell) => cell.value,
      },
      {
        Header: "E-mail",
        accessor: "user.email",
        filterable: true,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: "Data Início",
        accessor: "user.created_at",
        filterable: true,
        Cell: (cell) => <>{dateFormat(cell.value)}</>,
      },
      {
        Header: "Status",
        accessor: "cliente.status",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "ativo":
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
            case "inativo" || "cancelado" || "bloqueado":
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
          }
        },
      },
      {
        Header: "Ações",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/clientes-profile?cliente_id=${cellProps.row.original.user.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {cellProps.row.original.id === 1 ? null : cellProps.row.original
                  .user.status !== "inativo" ? (
                <li className="list-inline-item" title="Desativar">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      const clienteData = cellProps.row.original;
                      onClickDelete(clienteData);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
              ) : (
                <li className="list-inline-item" title="Reativar">
                  <Link
                    to="#"
                    className="text-success d-inline-block remove-item-btn"
                    onClick={() => {
                      const clienteData = cellProps.row.original;
                      onClickDelete(clienteData);
                    }}
                  >
                    <i className="ri-thumb-up-fill fs-16"></i>
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Clientes | Painel Líder Fibra";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={clientes}
        />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
          status={cliente?.cliente?.status}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
          status={"multiplos"}
        />
        <Container fluid>
          <BreadCrumb title="Clientes" pageTitle="Sistema" />
          <Row>
            <Col lg={12}>
              <Card id="clienteList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Clientes</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger me-1"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {!loading ? (
                      <TableContainer
                        columns={columns}
                        data={clientes || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Buscar clientes..."
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Clientes;
