import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import Loader from "../../../../Components/Common/Loader";

//import images
import progileBg from "../../../../assets/images/profile-bg.jpg";
import FeatherIcon from "feather-icons-react";

const Settings = () => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const [activeTab, setActiveTab] = useState("1");
  const [tipoCliente, setTipoCliente] = useState("cpf");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [rg, setRg] = useState("");
  const [revendedor, setRevendedor] = useState(user.data._id);
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("São Paulo");
  const [estado, setEstado] = useState("SP");
  const [dataNascimento, setDataNascimento] = useState("");
  const [vencimento, setVencimento] = useState("10");
  const [formaPagamento, setFormaPagamento] = useState("boleto");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(false);
  const [revendas, setRevendas] = useState([]);

  const formatNome = (event) => {
    let value = event.target.value.toUpperCase();
    value = value.replace(/[^A-Za-z\s]/g, "");
    setNome(value);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchUsers = useCallback(async () => {
    const user_logged = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          headers: {
            Authorization: `Bearer ${user_logged.token}`,
          },
        }
      );

      if (response.sucesso) {
        setRevendas(response.data);
      } else {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const cadastrar = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      tipo: tipoCliente,
      estado: estado,
      nome: nome,
      email: email,
      telefone: telefone,
      celular: celular,
      inscricao_estadual: inscricaoEstadual,
      rg: rg,
      revendedor: revendedor,
      cep: cep,
      endereco: endereco,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      data_nascimento: dataNascimento,
      vencimento: vencimento,
      forma_pagamento: formaPagamento,
      cpf: cpf,
      cnpj: cnpj,
    };

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/clientes`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: data,
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        window.location.href = "/clientes";
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const handleCepBlur = async () => {
    try {
      const _cep = cep.replace(/[^0-9]/, "");
      const response = await axios.get(`https://viacep.com.br/ws/${_cep}/json`);

      setEndereco(response.logradouro);
      setBairro(response.bairro);
      setCidade(response.localidade);
      setEstado(response.uf);
    } catch (error) {
      console.error(error);
    }
  };

  document.title = "Novo Cliente | Painel Líder Fibra";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content"></div>
            </div>
          </div>
          <Row>
            <Col xxl={12}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <FeatherIcon icon="user" height="15" />
                        Dados do Cliente
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form onSubmit={cadastrar} disabled={loading}>
                        <h5 style={{ padding: "20px 10px" }}>Dados Pessoais</h5>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="radioOptions"
                                className="form-label"
                              >
                                Tipo
                              </Label>
                              <Row id="radioOptions">
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCPF"
                                    value="cpf"
                                    checked={tipoCliente === "cpf"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCPF"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Física
                                  </Label>
                                </Col>
                                <Col lg={6}>
                                  <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioTipo"
                                    id="radioCNPJ"
                                    value="cnpj"
                                    checked={tipoCliente === "cnpj"}
                                    onChange={(e) =>
                                      setTipoCliente(e.target.value)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="radioCNPJ"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Pessoa Jurídica
                                  </Label>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {tipoCliente === "cpf"
                                  ? "Nome Completo"
                                  : "Razão Social"}
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                maxLength={255}
                                value={nome}
                                onChange={formatNome}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {tipoCliente === "cpf"
                                  ? "Nascimento"
                                  : "Início da Empresa"}
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  *
                                </span>
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                value={dataNascimento}
                                onChange={(e) =>
                                  setDataNascimento(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                E-mail
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                  }}
                                >
                                  *
                                </span>
                              </Label>
                              <div className="form-icon">
                                <Input
                                  type="email"
                                  className="form-control form-control-icon"
                                  placeholder="examplo@gmail.com"
                                  value={email}
                                  onChange={formatEmail}
                                />
                                <i className="ri-mail-unread-line"></i>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Telefone</Label>
                              <InputMask
                                mask="(99) 9999-9999"
                                placeholder="(00) 0000-0000"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Celular</Label>
                              <InputMask
                                mask="(99) 99999-9999"
                                placeholder="(00) 00000-0000"
                                value={celular}
                                onChange={(e) => setCelular(e.target.value)}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>

                          {tipoCliente === "cpf" ? (
                            <>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    CPF
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        fontSize: "10px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </Label>
                                  <InputMask
                                    mask="999.999.999-99"
                                    placeholder="000.000.000-00"
                                    value={cpf}
                                    onChange={(e) => setCpf(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label">RG</Label>
                                  <InputMask
                                    mask="99.999.999-9"
                                    placeholder="00.000.000-0"
                                    value={rg}
                                    onChange={(e) => setRg(e.target.value)}
                                  >
                                    {(inputProps) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                </div>
                              </Col>
                            </>
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  CNPJ
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      fontSize: "10px",
                                    }}
                                  >
                                    *
                                  </span>
                                </Label>
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  placeholder="00.000.000/0000-00"
                                  value={cnpj}
                                  onChange={(e) => setCnpj(e.target.value)}
                                >
                                  {(inputProps) => (
                                    <Input
                                      type="text"
                                      className="form-control"
                                      {...inputProps}
                                    />
                                  )}
                                </InputMask>
                              </div>
                            </Col>
                          )}

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Inscrição Estadual
                              </Label>
                              <InputMask
                                mask="999.999.999.999"
                                placeholder="000.000.000.000"
                                value={inscricaoEstadual}
                                onChange={(e) =>
                                  setInscricaoEstadual(e.target.value)
                                }
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Revendedor</Label>
                              {loading ? (
                                <Loader />
                              ) : user.data.role === "admin" ? (
                                <select
                                  className="form-select mb-3"
                                  value={revendedor}
                                  onChange={(e) =>
                                    setRevendedor(e.target.value)
                                  }
                                >
                                  {revendas.map((revenda) => {
                                    if (
                                      revenda.id === 1 ||
                                      revenda.tipo === "revendedor"
                                    )
                                      return (
                                        <option
                                          key={revenda.id}
                                          value={revenda.id}
                                        >
                                          {revenda.name}
                                        </option>
                                      );
                                    else return null;
                                  })}
                                </select>
                              ) : (
                                <select
                                  className="form-select mb-3"
                                  value={revendedor}
                                  disabled
                                >
                                  {revendas.map((revenda) => (
                                    <option key={revenda.id} value={revenda.id}>
                                      {revenda.name}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <h5 style={{ padding: "20px 10px" }}>
                          Endereço Principal
                        </h5>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">CEP</Label>
                              <InputMask
                                mask="99999-999"
                                placeholder="00000-000"
                                value={cep}
                                onChange={(e) => setCep(e.target.value)}
                                onBlur={handleCepBlur}
                              >
                                {(inputProps) => (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    {...inputProps}
                                  />
                                )}
                              </InputMask>
                            </div>
                          </Col>
                          <Col lg={8}>
                            <div className="mb-3">
                              <Label className="form-label">Endereço</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Rua, Avenida, etc..."
                                value={endereco}
                                onChange={(e) => setEndereco(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Número</Label>
                              <Input
                                type="number"
                                className="form-control"
                                placeholder="000"
                                maxLength={7}
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Complemento</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Casa, Apartamento, etc..."
                                maxLength={255}
                                value={complemento}
                                onChange={(e) => setComplemento(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Bairro</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Bairro"
                                maxLength={255}
                                value={bairro}
                                onChange={(e) => setBairro(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Cidade</Label>
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Cidade"
                                maxLength={255}
                                value={cidade}
                                onChange={(e) => setCidade(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Estado</Label>
                              <select
                                className="form-select mb-3"
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                              >
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <h5 style={{ padding: "20px 10px" }}>
                          Dados de Cobrança
                        </h5>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Vencimento</Label>
                              <select
                                className="form-select mb-3"
                                value={vencimento}
                                onChange={(e) => setVencimento(e.target.value)}
                              >
                                <option value="10">Dia 10</option>
                                <option value="20">Dia 20</option>
                                <option value="30">Dia 30</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                Forma de Pagamento
                              </Label>
                              <select
                                className="form-select mb-3"
                                value={formaPagamento}
                                onChange={(e) =>
                                  setFormaPagamento(e.target.value)
                                }
                              >
                                <option value="boleto">Boleto</option>
                                <option value="pix">Pix</option>
                                <option value="carnet">Carnê</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                              >
                                Cadastrar
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
