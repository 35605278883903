import React, { useState } from "react";
import {
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { set } from "date-fns";

const ModalPassword = ({ isOpen, toggle, revenda }) => {
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${revenda.id}/change-password`,
        {
          password: password,
          password_confirmation: passwordConfirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.sucesso) {
        toast.success(response.mensagem);
        setPassword("");
        setPasswordConfirmation("");
        toggle();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Mudança de Senha
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              Nova Senha
            </Label>
            <Input
              className="form-control"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="email-field" className="form-label">
              Confirmar Senha
            </Label>
            <Input
              className="form-control"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <FormFeedback type="invalid">{"Exemplo de erro"}</FormFeedback>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              onClick={toggle}
              type="button"
              color="light"
              disabled={loading}
            >
              {" "}
              Fechar{" "}
            </Button>

            <Button type="submit" color="success" disabled={loading}>
              Alterar
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalPassword;
