import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";
import axios from "axios";

const ModalVoip = ({ isOpen, toggle, revenda }) => {
  const [numerosLivres, setNumerosLivres] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRemoveItem = async (id) => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/voip/${id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchNumerosLivres();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const fetchNumerosLivres = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/voip/livres/${revenda.id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setNumerosLivres(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  }, [revenda.id]);

  useEffect(() => {
    if (isOpen) {
      fetchNumerosLivres();
    }
  }, [fetchNumerosLivres, isOpen]);

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Números Voip
      </ModalHeader>

      <ModalBody style={{ maxHeight: "400px" }}>
        <div className="mb-3">
          <Label className="form-label">Números Livres</Label>
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Usuário</th>
                  <th scope="col">Senha</th>
                  <th scope="col">Senha</th>
                </tr>
              </thead>
              <tbody>
                {numerosLivres.map((numero) => (
                  <tr key={numero.id}>
                    <td>{numero.usuario}</td>
                    <td>{numero.senha}</td>
                    <td>
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleRemoveItem(numero.id)}
                        title="Remover"
                      >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button
            onClick={toggle}
            type="button"
            color="light"
            disabled={loading}
          >
            {" "}
            Fechar{" "}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalVoip;
