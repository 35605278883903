import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import Loader from "../../../../Components/Common/Loader";

const TabProdutos = ({
  loading,
  setLoading,
  activeTab,
  revendedor,
  clienteId,
}) => {
  const [produtos, setProdutos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [numerosLivres, setNumerosLivres] = useState([]);
  const [numero, setNumero] = useState(0);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchNumerosLivres = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/voip/livres/${revendedor}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setNumerosLivres(response.data);
        if (response.data.length > 0) {
          setNumero(response.data[0].id);
        }
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
  }, [revendedor]);

  const fetchProdutosVoip = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/voip/${clienteId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.sucesso && response.sucesso === 1) {
        setProdutos(response.data);
      } else {
        toast.error(response.message);
        console.log(response);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao buscar os produtos.");
      console.log(error);
    }
  }, [clienteId]);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "3") {
      fetchProdutosVoip();
      fetchNumerosLivres();
    }

    setLoading(false);
  }, [fetchNumerosLivres, fetchProdutosVoip, setLoading, activeTab]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/voip/${numero}/delegar`,
        data: {
          cliente_id: clienteId,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchProdutosVoip();
        fetchNumerosLivres();
        toggleModal();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const handleLiberarItem = async (id) => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/voip/${id}/liberar`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchProdutosVoip();
        fetchNumerosLivres();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <h5 className="page-title">VOIP</h5>
        </Col>
        <Col lg="12">
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={toggleModal}>
              + Voip
            </Button>
          </div>

          <div className="table-responsive">
            {loading ? (
              <Loader />
            ) : (
              <table className="table table-centered table-nowrap table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Usuário</th>
                    <th scope="col">Senha</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {produtos.map((produto) => (
                    <tr key={produto.id}>
                      <td>{produto.usuario}</td>
                      <td>{produto.senha}</td>
                      <td>
                        {produto.status === "ativo" ? (
                          <Badge
                            color="success"
                            className="bg-success font-size-12"
                          >
                            Ativo
                          </Badge>
                        ) : produto.status === "bloqueado" ? (
                          <Badge
                            color="warning"
                            className="bg-warning font-size-12"
                          >
                            Bloqueado
                          </Badge>
                        ) : (
                          <Badge
                            color="danger"
                            className="bg-danger font-size-12"
                          >
                            Inativo
                          </Badge>
                        )}
                      </td>
                      <td>
                        <Link
                          to="#"
                          className="text-danger d-inline-block remove-item-btn"
                          onClick={() => handleLiberarItem(produto.id)}
                          title="Remover"
                        >
                          <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </Col>
      </Row>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delegar Número VOIP</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <Label className="form-label">Número</Label>
                  <Input
                    type="select"
                    className="form-control"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  >
                    {numerosLivres.map((numero) => (
                      <option key={numero.id} value={numero.id}>
                        {numero.did}
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => handleSubmit(e)}>
            Delegar
          </Button>
          <Button color="light" onClick={toggleModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default TabProdutos;
