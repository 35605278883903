import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";

const ModalVoip = ({ isOpen, toggle, revenda }) => {
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [did, setDid] = useState("");
  const [senha, setSenha] = useState("");
  const [url, setUrl] = useState("sip.liderfibra.com");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      console.log(revenda);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/voip`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          revendedor_id: revenda.id,
          usuario: usuario,
          did: did,
          senha: senha,
          url: url,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        setUsuario("");
        setDid("");
        setSenha("");
        toggle();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Adicionar Número Voip
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              Usuário
            </Label>
            <InputMask
              mask="99999999"
              placeholder="Digite aqui"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            >
              {(inputProps) => (
                <Input type="text" className="form-control" {...inputProps} />
              )}
            </InputMask>
          </div>
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              URL
            </Label>
            <Input
              className="form-control"
              placeholder="Digite aqui"
              value={url}
              type="text"
              onChange={(e) => setUrl(e.target.value)}
              maxLength={255}
              readOnly
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              DID
            </Label>
            <InputMask
              mask="9999999999"
              placeholder="DID"
              value={did}
              onChange={(e) => setDid(e.target.value)}
            >
              {(inputProps) => (
                <Input type="text" className="form-control" {...inputProps} />
              )}
            </InputMask>
          </div>

          <div className="mb-3">
            <Label htmlFor="email-field" className="form-label">
              Senha
            </Label>
            <Input
              type="password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              maxLength={20}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              onClick={toggle}
              type="button"
              color="light"
              disabled={loading}
            >
              {" "}
              Fechar{" "}
            </Button>

            <Button type="submit" color="success" disabled={loading}>
              Adicionar
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalVoip;
