import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";

const ModalUsers = ({ isOpen, toggle, isEdit, revenda, fetchUsers }) => {
  const [loading, setLoading] = useState(false);
  const [cnpj, setCnpj] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [tipo, setTipo] = useState("revendedor");
  const [status, setStatus] = useState("ativo");

  useEffect(() => {
    if (isEdit && revenda) {
      revenda.dados.map((item) => {
        if(item.nome==='CNPJ') return setCnpj(item.value);
        return null;
      });
      setNome(revenda.name);
      setEmail(revenda.email);
      setSenha(revenda.password);
      setTipo(revenda.tipo);
      setStatus(revenda.status);
    }
  }, [isEdit, revenda]);

  const revendamocalstatus = [
    {
      options: [
        { label: "Ativo", value: "ativo" },
        { label: "Inativo", value: "inativo" },
        { label: "Cancelado", value: "cancelado" },
        { label: "Suspenso", value: "suspenso" },
        { label: "Aguardando", value: "aguardando" },
        { label: "Pendente", value: "pendente" },
        { label: "Bloqueado", value: "bloqueado" },
      ],
    },
  ];

  const revendamocaltipo = [
    {
      options: [
        { label: "Revendedor", value: "revendedor" },
        { label: "Usuário", value: "user" },
        { label: "Administrador", value: "admin" },
      ],
    },
  ];

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let user = {
      name: nome,
      email: email,
      password: senha,
      cnpj: cnpj,
      tipo: tipo,
      status: status,
    };

    if (isEdit) {
      // If we are editing a user, send a PUT request
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/users/${revenda.id}`,
          user
        );
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          toggle();
          fetchUsers();
        } else {
          toast.error(response.mensagem);
        }
      } catch (error) {
        toast.error("Erro ao acessar a API.");
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users`,
          user
        );
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          toggle();
          fetchUsers();
        } else {
          toast.error(response.mensagem);
        }
      } catch (error) {
        toast.error("Erro ao acessar a API.");
      }
    }
    setLoading(false);
  };

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {!!isEdit ? "Editar Usuário" : "Adicionar Usuário"}
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={handleSubmit}>
        <ModalBody>
          {isEdit ? (
            <div className="mb-3" id="modal-id">
              <Label htmlFor="id-field1" className="form-label">
                ID
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="ID"
                value={revenda.id}
                readOnly
              />
            </div>
          ) : null}

          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              Nome Completo
            </Label>
            <Input
              className="form-control"
              placeholder="Nome Completo"
              value={nome}
              type="text"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="email-field" className="form-label">
              E-mail
            </Label>
            <Input
              type="email"
              placeholder="fulano@algumacoisa.com"
              className="form-control"
              value={email}
              onChange={formatEmail}
            />
            <FormFeedback type="invalid">{"Exemplo de erro"}</FormFeedback>
          </div>

          {tipo === "revendedor" ? (
            <div className="mb-3">
              <Label className="form-label">CNPJ</Label>
              <InputMask
                mask="99.999.999/9999-99"
                placeholder="00.000.000/0000-00"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
              >
                {(inputProps) => (
                  <Input type="text" className="form-control" {...inputProps} />
                )}
              </InputMask>
            </div>
          ): null}

          {isEdit ? null : (
            <div className="mb-3">
              <Label htmlFor="email-field" className="form-label">
                Senha
              </Label>
              <Input 
              type="password" 
              placeholder="Digite a senha"
              className="form-control"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              />
            </div>
          )}

          <div className="mb-3">
            <Label htmlFor="status-field" className="form-label">
              Tipo
            </Label>

            <Input
              type="select"
              className="form-select"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              disabled={revenda.id ? revenda.id === 1 : false}
            >
              {revendamocaltipo.map((item, key) => (
                <React.Fragment key={key}>
                  {item.options.map((item, key) => (
                    <option value={item.value} key={key}>
                      {item.label}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </Input>
          </div>

          <div className="mb-3">
            <Label htmlFor="status-field" className="form-label">
              Status
            </Label>

            <Input
              type="select"
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled={revenda.id ? revenda.id === 1 : false}
            >
              {revendamocalstatus.map((item, key) => (
                <React.Fragment key={key}>
                  {item.options.map((item, key) => (
                    <option value={item.value} key={key}>
                      {item.label}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </Input>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              onClick={toggle}
              type="button"
              color="light"
              disabled={loading}
            >
              {" "}
              Fechar{" "}
            </Button>

            <Button type="submit" color="success" disabled={loading}>
              {" "}
              {!!isEdit ? "Atualizar" : "Criar"}{" "}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalUsers;
