import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import axios from "axios";

const AddProductModal = ({ show, onCloseClick, fetchProducts }) => {
  const [tipo, setTipo] = useState("cliente");
  const [nome, setNome] = useState("");
  const [preco, setPreco] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleAddProduct = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/produtos`,
        {
          tipo: tipo,
          nome: nome,
          valor: preco,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchProducts();
        onCloseClick();
      } else {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick}>
      <ModalHeader className="modal-title" toggle={onCloseClick}>
        Adicionar Produto
      </ModalHeader>
      <ModalBody>
        <form action="#">
          <div className="row g-3">
            <Col xxl={12}>
              <div>
                <label htmlFor="firstName" className="form-label">
                  Nome do Produto
                </label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Digite aqui o nome do produto"
                  maxLength={80}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label htmlFor="firstName" className="form-label">
                  Preço
                </label>
                <NumericFormat
                  className="form-control"
                  placeholder="R$ 0,00"
                  value={preco}
                  onValueChange={({ value }) => setPreco(parseFloat(value))}
                  prefix="R$ "
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label">Tipo</Label>
                <select
                  className="form-select mb-3"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="cliente">Cliente</option>
                  <option value="revendedor">Revendedor</option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <Button color="light" onClick={onCloseClick} disabled={loading}>
                  Fechar
                </Button>
                <Button
                  color="success"
                  onClick={handleAddProduct}
                  disabled={loading}
                >
                  Adicionar
                </Button>
              </div>
            </Col>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default AddProductModal;
