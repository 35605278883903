import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

import Loader from "../../../Components/Common/Loader";

const ModalProdutos = ({ isOpen, toggle, revenda }) => {
  const [revendaProdutos, setrevendaProdutos] = useState([]);
  const [revendaProdutosUnicos, setrevendaProdutosUnicos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState(3);
  const [tipoProduto, setTipoProduto] = useState("unico");
  const [tipoDelete, setTipoDelete] = useState("unico");
  const [meses, setMeses] = useState(1);

  const handleRemoveItem = async (id) => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      var response;
      if (tipoDelete === "unico") {
        response = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}/revendedor/produtos/unico/${id}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      } else {
        response = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}/revendedor/produtos/${id}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      }

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchProdutos();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const cadastrarProduto = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);

    try {
      var response;
      if (tipoProduto === "unico") {
        response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/revendedor/produtos/unico`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          data: {
            revendedor_id: revenda.id,
            produto_id: produto,
            meses: meses,
          },
        });
      } else {
        response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/revendedor/produtos`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          data: {
            revendedor_id: revenda.id,
            produto_id: produto,
          },
        });
      }

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchProdutos();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProdutos = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    let response;
    try {
      response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/revendedor/produtos/${revenda.id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setrevendaProdutos(
          response.data.reduce((acc, prod) => {
            const id = prod.produto.id;
            if (!acc[id]) {
              acc[id] = {
                ...prod,
                count: 1,
              };
            } else {
              acc[id].produto.valor += prod.produto.valor;
              acc[id].count += 1;
            }
            return acc;
          }, {})
        );
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    try {
      response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/revendedor/produtos/unico/${revenda.id}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setrevendaProdutosUnicos(
          response.data.reduce((acc, prod) => {
            const id = prod.produto.id;
            if (!acc[id]) {
              acc[id] = {
                ...prod,
                count: 1,
              };
            } else {
              acc[id].produto.valor += prod.produto.valor;
              acc[id].count += 1;
            }
            return acc;
          }, {})
        );
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  }, [revenda.id]);

  const fetchSystemProducts = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/produtos?tipo=revendedor`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setProdutos(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchProdutos();
      fetchSystemProducts();
    }
  }, [fetchSystemProducts, fetchProdutos, isOpen]);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  let total = 0;

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Produtos
      </ModalHeader>

      <ModalBody style={{ maxHeight: "650px" }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="mb-3">
              <Label className="form-label">Produtos Fixos</Label>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Produto</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(revendaProdutos).map((prodGroup) => {
                    total += prodGroup.produto.valor;
                    return (
                      <tr key={prodGroup.id}>
                        <td style={{ width: "60%" }}>
                          {prodGroup.produto.nome}
                          {prodGroup.count > 1 && ` (x${prodGroup.count})`}
                        </td>
                        <td>{formatCurrency(prodGroup.produto.valor)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mb-3">
              <Label className="form-label">Produtos Únicos</Label>
              <table className="table table-striped">
                <tbody>
                  {Object.values(revendaProdutosUnicos).map((prodGroup) => {
                    total += prodGroup.produto.valor;
                    return (
                      <tr key={prodGroup.id}>
                        <td style={{ width: "60%" }}>
                          {prodGroup.produto.nome}
                          {prodGroup.count > 1 && ` (x${prodGroup.count})`}
                        </td>
                        <td>{formatCurrency(prodGroup.produto.valor)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="mb-3">
          <Label className="form-label">Total</Label>
          <table className="table table-striped">
            <tbody>
              <tr>
                <td style={{ width: "60%" }}></td>
                <td>{formatCurrency(total)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button
            onClick={toggle}
            type="button"
            color="light"
            disabled={loading}
          >
            {" "}
            Fechar{" "}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalProdutos;
