import React, { useEffect, useState, useMemo, useCallback } from "react";

import { Container, Button, Row } from "reactstrap";

import { toast, ToastContainer } from "react-toastify";

// RangeSlider
import DeleteModal from "../../../Components/Common/DeleteModalProdutos";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { Published } from "./EcommerceProductCol";

import axios from "axios";

import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";

//redux
import { Link } from "react-router-dom";
import Loader from "../../../Components/Common/Loader";

const EcommerceProducts = () => {
  const [addProductModal, setAddProductModal] = useState(false);
  const [editarProductModal, setEditarProductModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState(null);

  const fetchProducts = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios.get(
        user.data.role === "admin"
          ? `${process.env.REACT_APP_API_URL}/produtos`
          : `${process.env.REACT_APP_API_URL}/produtos?tipo=cliente`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.sucesso && response.sucesso === 1) {
        setProductList(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    if (product) {
      setLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/produtos/${product.id}/toggle-active`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          fetchProducts();
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API");
        console.log(error);
      }
      setLoading(false);
      setDeleteModal(false);
    }
  };

  // Displat Delete Button
  const [dele, setDele] = useState(0);
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  const deleteMultiple = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach(async (element) => {
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/produtos/${element.value}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          fetchProducts();
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
      del.style.display = "none";
    });
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (cell) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox form-check-input"
              value={cell.row.original._id}
              onClick={() => displayDelete()}
            />
          );
        },
      },
      {
        Header: "Product",
        Cell: (product) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to="/apps-ecommerce-product-details"
                    className="text-dark"
                  >
                    {" "}
                    {product.row.original.nome}
                  </Link>
                </h5>
                {/* <p className="text-muted mb-0">
                  Category :{" "}
                  <span className="fw-medium">
                    {" "}
                    {product.row.original.category}
                  </span>
                </p> */}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Preço",
        accessor: "valor",
        Cell: (cell) => {
          return <>{formatCurrency(cell.value)}</>;
        },
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        filterable: true,

        //colocar cell.value com a primeira letra maiuscula
        Cell: (cell) => {
          return (
            <>{cell.value?.charAt(0).toUpperCase() + cell.value.slice(1)}</>
          );
        },
      },
      {
        Header: "Publicado",
        accessor: "created_at",
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cell) => {
          return (
            <span
              className={`badge text-uppercase badge-soft-${
                cell.value === "ativo" ? "success" : "danger"
              }`}
            >
              {" "}
              {cell.value?.charAt(0).toUpperCase() + cell.value.slice(1)}{" "}
            </span>
          );
        },
      },
      {
        Header: "Ações",
        Cell: (cellProps) => {
          return (
            <ul>
              <li className="list-inline-item" title="Editar">
                <Link
                  to="#"
                  className="px-2 text-primary"
                  onClick={() => {
                    setProduct(cellProps.row.original);
                    setEditarProductModal(true);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {cellProps.row.original.status !== "inativo" ? (
                <li className="list-inline-item" title="Desativar">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      onClickDelete(cellProps.row.original);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
              ) : (
                <li className="list-inline-item" title="Reativar">
                  <Link
                    to="#"
                    className="text-success d-inline-block remove-item-btn"
                    onClick={() => {
                      onClickDelete(cellProps.row.original);
                    }}
                  >
                    <i className="ri-thumb-up-fill fs-16"></i>
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    ],
    []
  );
  document.title = "Produtos | Painel Líder Fibra";

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <AddProductModal
        show={addProductModal}
        onCloseClick={() => setAddProductModal(false)}
        fetchProducts={fetchProducts}
      />
      <EditProductModal
        show={editarProductModal}
        onCloseClick={() => setEditarProductModal(false)}
        product={product}
        fetchProducts={fetchProducts}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Produtos" pageTitle="Sistema" />
        <Row>
          <div className="col-xl-12 col-lg-12">
            <div>
              <div className="card">
                <div className="card-header border-0">
                  <div className="row align-items-center">
                    <div className="col text-end">
                      <Button
                        color="success"
                        onClick={() => setAddProductModal(true)}
                      >
                        <i className="ri-add-line align-middle me-2"></i>{" "}
                        Adicionar Produto
                      </Button>
                    </div>
                    <div className="col-auto">
                      <div id="selection-element">
                        <div className="my-n1 d-flex align-items-center text-muted">
                          Selecionado{" "}
                          <div
                            id="select-content"
                            className="text-body fw-semibold px-1"
                          >
                            {dele}
                          </div>{" "}
                          resultado(s){" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-danger ms-3"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            Desativar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="card-body pt-0">
                    {productList && productList.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={productList}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        divClass="table-responsive mb-1"
                        tableClass="mb-0 align-middle table-borderless"
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Buscar Produtos..."
                      />
                    ) : (
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: "72px", height: "72px" }}
                          ></lord-icon>
                        </div>

                        <div className="mt-4">
                          <h5>Nenhum produto cadastrado.</h5>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceProducts;
