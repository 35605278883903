import React, { useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import { Card, CardBody, Spinner, Button } from "reactstrap";
import { toast } from "react-toastify";

//redux
import TableContainer from "../../../Components/Common/TableContainer";
import ModalProdutos from "./ModalProdutos";

const EnviosPendentes = () => {
  const [transation, setTransation] = useState([]);
  const [revendedor, setRevendedor] = useState({});
  const [revendaProdutos, setRevendaProdutos] = useState({});
  const [revendaProdutosUnicos, setRevendaProdutosUnicos] = useState({});
  const [modalProdutos, setModalProdutos] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggleProdutos = useCallback(() => {
    setModalProdutos((prevState) => !prevState);
  }, []);

  const handleModalClick = useCallback(
    (arg) => {
      setRevendedor(arg.revendedor);
      setRevendaProdutos(arg.faturas.produtos_fixos);
      setRevendaProdutosUnicos(arg.faturas.produtos_adicionais);
      toggleProdutos();
    },
    [toggleProdutos]
  );

  const fetchFaturas = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/financeiro/em-aberto`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.sucesso && response.sucesso === 1) {
        setTransation(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API.");
      console.log(error);
    }
    setLoading(false);
  }, [setLoading, setTransation]);

  useEffect(() => {
    fetchFaturas();
  }, [fetchFaturas]);

  const handleButtonClick = useCallback(async (revenda) => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/financeiro/fatura/${revenda.id}`,
        {
          vencimento: '2023-09-20'
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
        fetchFaturas();
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API.");
      console.log(error);
    }
    setLoading(false);
  }, [fetchFaturas]);

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Revendedor",
        accessor: "revendedor.name",
        Cell: (cell) => <b>{cell.value}</b>,
      },
      {
        Header: "Valor",
        accessor: "faturas",
        Cell: (cell) => {
          let total = 0;
          cell.value.produtos_fixos.forEach((produto) => {
            total += produto.produto.valor;
          });

          // Itera sobre produtos adicionais e soma os valores
          cell.value.produtos_adicionais.forEach((produto) => {
            total += produto.produto.valor;
          });
          return <>{formatCurrency(total)}</>;
        },
      },
      {
        Header: "Ações",
        Cell: (cell) => (
          <>
            <Button
              color="danger"
              className="btn btn-sm"
              disabled={loading}
              onClick={() => handleButtonClick(cell.row.original.revendedor)}
            >
              Preparar Fatura
            </Button>
            <Button
              color="primary"
              className="btn btn-sm"
              style={{ marginLeft: "10px" }}
              onClick={() => {handleModalClick(cell.row.original);}}
            >
              Ver Itens
            </Button>
          </>
        ),
      },
    ],
    [handleButtonClick, handleModalClick, loading]
  );

  return (
    <React.Fragment>
      <ModalProdutos
        isOpen={modalProdutos}
        toggle={toggleProdutos}
        revenda={revendedor}
        revendaProdutos={revendaProdutos}
        revendaProdutosUnicos={revendaProdutosUnicos}
      />
      <Card>
        {/* <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">Envios não preparados</h5>
            </Col>
          </Row>
        </CardHeader> */}
        <CardBody>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <TableContainer
              columns={columns}
              data={transation || []}
              isGlobalFilter={true}
              customPageSize={10}
              className="custom-header-css"
              divClass="table-responsive table-card mb-4"
              tableClass="align-middle table-nowrap mb-0"
              theadClass="table-light table-nowrap"
              thClass="table-light text-muted"
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EnviosPendentes;
