import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

const FaturasRevendedores = () => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const [loading, setLoading] = useState(false);
  const [faturas, setFaturas] = useState([]);

  const [fatura, setFatura] = useState([]);
  const [itensFatura, setItensFatura] = useState([]);

  // Delete fatura
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_center, setmodal_center] = useState(false);
  const tog_center = useCallback(() => {
    setmodal_center(!modal_center);
    sessionStorage.setItem("show_warning", "false");
  }, [modal_center]);

  // Delete Data
  const onClickDelete = (fatura) => {
    setFatura(fatura);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteCustomer = async () => {
    if (fatura) {
      setLoading(true);
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/faturas/${
            fatura.user.status === "ativo" ? "desativar" : "ativar"
          }/${fatura.user.id}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          toast.success(response.mensagem);
          fetchFaturas();
        } else {
          toast.error(response.mensagem);
          console.log(response);
        }
      } catch (error) {
        toast.error("Erro na API");
        console.log(error);
      }
      setLoading(false);
      setDeleteModal(false);
    }
  };

  const fetchFaturas = useCallback(async () => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/financeiro/faturas-revendas`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.sucesso && response.sucesso === 1) {
        setFaturas(response.data);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchFaturas();
  }, [fetchFaturas]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".faturaCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = async () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach(async (element) => {
      try {
        const response = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API_URL}/faturas/desativar/${element.value}`,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.sucesso && response.sucesso === 1) {
          console.log(`Cliente ID ${element.value} desativado com sucesso!`);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
      toast.success("Clientes desativados com sucesso!");
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;

    fetchFaturas();
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".faturaCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "R$ 0,00";
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const dateFormat = (date) => {
    let d = new Date(date),
      months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
    return (
      d.getDate() +
      " / " +
      months[d.getMonth()] +
      " / " +
      d.getFullYear()
    ).toString();
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="faturaCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "",
        accessor: "id",
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: "Revendedor",
        accessor: "revendedor.name",
        filterable: true,
        Cell: (cell) => <b>{cell.value}</b>,
      },
      {
        Header: "Valor",
        accessor: "valor",
        filterable: true,
        Cell: (cell) => {
          return formatCurrency(cell.value);
        },
      },
      {
        Header: "Data Vencimento",
        accessor: "vencimento",
        filterable: true,
        Cell: (cell) => <>{dateFormat(cell.value)}</>,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "waiting":
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  Em espera
                </span>
              );
            case "canceled":
              return (
                <span className="badge text-uppercase badge-soft-dark">
                  Cancelada
                </span>
              );
            case "paid":
              return (
                <span className="badge text-uppercase badge-soft-success">
                  Pago
                </span>
              );
            case "settled":
              return (
                <span className="badge text-uppercase badge-soft-success">
                  Pago
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {cell.value}
                </span>
              );
          }
        },
      },
      {
        Header: "Ações",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    setFatura(cellProps.row.original);
                    setItensFatura(JSON.parse(cellProps.row.original.itens));
                    tog_center();
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll, tog_center]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Faturas Revendedores | Painel Líder Fibra";
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <ModalHeader toggle={() => setmodal_center(false)}>
          Itens da Fatura
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="mt-4">
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Produto
                    </th>
                    <th scope="col" style={{ textAlign: "right" }}>
                      Valor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {itensFatura.map((item, key) => (
                    <tr key={key}>
                      <td style={{ textAlign: "left" }}>
                        <b>{item.name}</b>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {formatCurrency(item.value / 100)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-center">
            <Button color="light" onClick={() => setmodal_center(false)}>
              Fechar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={faturas}
        />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
          status={fatura?.fatura?.status}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
          status={"multiplos"}
        />
        <Container fluid>
          <BreadCrumb title="Faturas Revendedores" pageTitle="Financeiro" />
          <Row>
            <Col lg={12}>
              <Card id="faturaList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Faturas</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger me-1"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {!loading ? (
                      <TableContainer
                        columns={columns}
                        data={faturas || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={20}
                        className="custom-header-css"
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Buscar faturas..."
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaturasRevendedores;
