import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { set } from "date-fns";

const ModalSetores = ({ isOpen, toggle, revenda }) => {
  const [loading, setLoading] = useState(false);
  const [selectedSetores, setSelectedSetores] = useState([]);
  const [setores, setSetores] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedSetores.includes(id)) {
      setSelectedSetores((prevSetores) =>
        prevSetores.filter((setorId) => setorId !== id)
      );
    } else {
      setSelectedSetores((prevSetores) => [...prevSetores, id]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user-setores/${revenda.id}/update`,
        { setores: selectedSetores },
        {
          headers: {
            Authorization: `Bearer ${authUser.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.sucesso) {
        toast.success(response.mensagem);
        toggle();
        setSelectedSetores([]);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
      console.log(error);
    }
    setLoading(false);
  };

  const fetchSetores = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/user-setores`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.sucesso && response.sucesso === 1) {
        setSetores(response.data);
        setSelectedSetores(revenda.setores.map((setor) => setor.id));
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  }, [revenda]);

  useEffect(() => {
    if (isOpen) {
      fetchSetores();
    }
  }, [fetchSetores, isOpen, revenda]);

  return (
    <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Setores
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-3">
            <Row>
              {setores.map((setor) => (
                <Col lg={6} key={setor.id}>
                  <Input
                    className="form-check-input"
                    style={{ marginRight: "0.5rem" }}
                    type="checkbox"
                    value={setor.id}
                    checked={selectedSetores.includes(setor.id)}
                    onChange={() => handleCheckboxChange(setor.id)}
                  />
                  <Label className="form-label">
                    {setor.setor}
                  </Label>
                </Col>
              ))}
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              onClick={toggle}
              type="button"
              color="light"
              disabled={loading}
            >
              {" "}
              Fechar{" "}
            </Button>

            <Button type="submit" color="success" disabled={loading}>
              Alterar
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ModalSetores;
