import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import ModalUsers from "./ModalUsers";
import ModalVoip from "./ModalVoip";
import ModalNumeros from "./ModalNumeros";
import ModalProdutos from "./ModalProdutos";
import ModalPassword from "./ModalPassword";
import ModalSetores from "./ModalSetores";
import { Link } from "react-router-dom";
import axios from "axios";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//redux
import TableContainer from "../../../Components/Common/TableContainer";

import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [revendas, setRevendas] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [revenda, setRevenda] = useState([]);

  // Delete revenda
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  // Password revenda
  const [passwordModal, setPasswordModal] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalVoip, setModalVoip] = useState(false);
  const [modalNumeros, setModalNumeros] = useState(false);
  const [modalProdutos, setModalProdutos] = useState(false);
  const [setoresModal, setSetoresModal] = useState(false);

  const toggle = useCallback(() => {
    setModal((prevState) => !prevState);
  }, []);

  const toggleVoip = useCallback(() => {
    setModalVoip((prevState) => !prevState);
  }, []);

  const toggleNumeros = useCallback(() => {
    setModalNumeros((prevState) => !prevState);
  }, []);

  const toggleProdutos = useCallback(() => {
    setModalProdutos((prevState) => !prevState);
  }, []);

  const togglePassword = useCallback(() => {
    setPasswordModal((prevState) => !prevState);
  }, []);

  const toggleSetores = useCallback(() => {
    setSetoresModal((prevState) => !prevState);
  }, []);

  // Delete Data
  const onClickDelete = (revenda) => {
    setRevenda(revenda);
    setDeleteModal(true);
  };
  // Delete Data
  const handleDeleteCustomer = async () => {
    if (revenda) {
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleCustomerClick = useCallback(
    (arg) => {
      setRevenda(arg);
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const handleVoipClick = useCallback(
    (arg) => {
      setRevenda(arg);
      toggleVoip();
    },
    [toggleVoip]
  );

  const handleLivresClick = useCallback(
    (arg) => {
      setRevenda(arg);
      toggleNumeros();
    },
    [toggleNumeros]
  );

  const handleProdutosClick = useCallback(
    (arg) => {
      setRevenda(arg);
      toggleProdutos();
    },
    [toggleProdutos]
  );

  const handlePasswordClick = useCallback(
    (arg) => {
      setRevenda(arg);
      togglePassword();
    },
    [togglePassword]
  );

  const handleSetoresClick = useCallback(
    (arg) => {
      setRevenda(arg);
      toggleSetores();
    },
    [toggleSetores]
  );

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      if (response.sucesso) {
        setRevendas(response.data);
      } else {
        toast.error(response.mensagem);
      }
    } catch (error) {
      toast.error("Erro ao acessar a API.");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Add Data
  const handleCustomerClicks = () => {
    setRevenda("");
    setIsEdit(false);
    toggle();
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".revendaCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      //deletar vários
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".revendaCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return cellProps.row.original.id === 1 ? null : (
            <input
              type="checkbox"
              className="revendaCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "",
        accessor: "id",
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        filterable: true,
        Cell: (cell) => {
          return <b>{cell.value}</b>;
        },
      },
      {
        Header: "E-mail",
        accessor: "email",
        filterable: true,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "admin":
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {" "}
                  {"Administrador"}{" "}
                </span>
              );
            case "user":
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {" "}
                  {"Usuário"}{" "}
                </span>
              );
            case "revendedor":
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {" "}
                  {"Revendedor"}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
          }
        },
      },
      {
        Header: "Data Início",
        accessor: "created_at",
        filterable: true,
        Cell: (cell) => <>{dateFormat(cell.value)}</>,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "ativo":
              return (
                <span className="badge text-uppercase badge-soft-success">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
            case "inativo" || "cancelado" || "bloqueado":
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {" "}
                  {cell.value}{" "}
                </span>
              );
          }
        },
      },
      {
        Header: "Ações",
        Cell: (cellProps) => {
          const authUser = JSON.parse(sessionStorage.getItem("authUser"));
          const mudar_senha =
            authUser.data.role !== "admin"
              ? authUser.data._id === cellProps.row.original.id
              : true;
          const setores =
            (authUser.data.role === "admin" || authUser.data.role === "user") &&
            (cellProps.row.original.tipo === "admin" ||
              cellProps.row.original.tipo === "user");
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const revendaData = cellProps.row.original;
                    handleCustomerClick(revendaData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              {mudar_senha && (
                <li className="list-inline-item" title="Mudar Senha">
                  <Link
                    to="#"
                    className="text-warning d-inline-block remove-item-btn"
                    onClick={() => {
                      const revendaData = cellProps.row.original;
                      handlePasswordClick(revendaData);
                    }}
                  >
                    <i className="ri-lock-fill fs-16"></i>
                  </Link>
                </li>
              )}

              {setores && (
                <li className="list-inline-item" title="Setores">
                  <Link
                    to="#"
                    className="text-primary d-inline-block remove-item-btn"
                    onClick={() => {
                      const revendaData = cellProps.row.original;
                      handleSetoresClick(revendaData);
                    }}
                  >
                    <i className="ri-folder-user-fill fs-16"></i>
                  </Link>
                </li>
              )}

              {cellProps.row.original.id === 1 ? null : (
                <>
                  {cellProps.row.original.tipo !== "revendedor" ? null : (
                    <>
                      <li
                        className="list-inline-item edit"
                        title="Adicionar VOIP"
                      >
                        <Link
                          to="#"
                          className="text-success d-inline-block edit-item-btn"
                          onClick={() => {
                            const revendaData = cellProps.row.original;
                            handleVoipClick(revendaData);
                          }}
                        >
                          <i className="ri-phone-fill fs-16">+</i>
                        </Link>
                      </li>
                      <li
                        className="list-inline-item edit"
                        title="Números Livres"
                      >
                        <Link
                          to="#"
                          className="text-warning d-inline-block edit-item-btn"
                          onClick={() => {
                            const revendaData = cellProps.row.original;
                            handleLivresClick(revendaData);
                          }}
                        >
                          <i className="ri-phone-fill fs-16"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item edit" title="Produtos">
                        <Link
                          to="#"
                          className="text-primary d-inline-block edit-item-btn"
                          onClick={() => {
                            const revendaData = cellProps.row.original;
                            handleProdutosClick(revendaData);
                          }}
                        >
                          <i className="ri-suitcase-fill fs-16"></i>
                        </Link>
                      </li>
                    </>
                  )}

                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => {
                        const revendaData = cellProps.row.original;
                        onClickDelete(revendaData);
                      }}
                    >
                      <i className="ri-delete-bin-5-fill fs-16"></i>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          );
        },
      },
    ],
    [
      handleCustomerClick,
      checkedAll,
      handleVoipClick,
      handleLivresClick,
      handleProdutosClick,
      handlePasswordClick,
      handleSetoresClick,
    ]
  );

  const dateFormat = (date) => {
    let d = new Date(date),
      months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Usuários | Painel Líder Fibra";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={revendas}
        />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <BreadCrumb title="Usuários" pageTitle="Sistema" />
          <Row>
            <Col lg={12}>
              <Card id="revendaList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Usuários</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger me-1"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Adicionar Usuário
                        </button>{" "}
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {!loading ? (
                      <TableContainer
                        columns={columns}
                        data={revendas || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        handleCustomerClick={handleCustomerClicks}
                        theadClass="table-light text-muted"
                        SearchPlaceholder="Buscar revendas..."
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>

                  <ModalUsers
                    isOpen={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    revenda={revenda}
                    fetchUsers={fetchUsers}
                  />
                  <ModalVoip
                    isOpen={modalVoip}
                    toggle={toggleVoip}
                    revenda={revenda}
                  />

                  <ModalNumeros
                    isOpen={modalNumeros}
                    toggle={toggleNumeros}
                    revenda={revenda}
                  />

                  <ModalProdutos
                    isOpen={modalProdutos}
                    toggle={toggleProdutos}
                    revenda={revenda}
                  />

                  <ModalPassword
                    revenda={revenda}
                    isOpen={passwordModal}
                    toggle={togglePassword}
                  />

                  <ModalSetores
                    revenda={revenda}
                    isOpen={setoresModal}
                    toggle={toggleSetores}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
