import React, { useState, useCallback, useEffect } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import Loader from "../../../../Components/Common/Loader";

const TabPerfil = ({
  tipoCliente,
  status,
  setStatus,
  enderecoCobranca,
  setEnderecoCobranca,
  enderecos,
  nome,
  setNome,
  email,
  setEmail,
  revendedor,
  setRevendedor,
  dataNascimento,
  setDataNascimento,
  vencimento,
  setVencimento,
  formaPagamento,
  setFormaPagamento,
  dadosCliente,
  setDadosCliente,
  loading,
  setLoading,
  revendas,
  clienteId
}) => {
  const user = JSON.parse(sessionStorage.getItem("authUser"));

  const formatNome = (event) => {
    let value = event.target.value.toUpperCase();
    value = value.replace(/[^A-Za-z\s]/g, "");
    setNome(value);
  };

  const formatEmail = (event) => {
    let value = event.target.value;
    value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

    const atSymbolCount = value.split("@").length - 1;
    if (atSymbolCount > 1) {
      const index = value.lastIndexOf("@");
      value = value.slice(0, index) + value.slice(index + 1);
    }

    setEmail(value);
  };

  const editar = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      nome: nome,
      email: email,
      revendedor: revendedor,
      status: status,
      endereco_cobranca: enderecoCobranca,
      data_nascimento: dataNascimento,
      vencimento: vencimento,
      forma_pagamento: formaPagamento,
      dados_cliente: dadosCliente,
    };

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/clientes/${clienteId}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: data,
      });

      if (response.sucesso && response.sucesso === 1) {
        toast.success(response.mensagem);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Form onSubmit={editar} disabled={loading}>
        <h5 style={{ padding: "20px 10px" }}>Dados Pessoais</h5>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="radioOptions" className="form-label">
                Tipo
              </Label>
              <Row id="radioOptions">
                <Col lg={6}>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="radioTipo"
                    id="radioCPF"
                    defaultValue="cpf"
                    checked={tipoCliente === "cpf"}
                    disabled
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="radioCPF"
                    style={{ marginLeft: "10px" }}
                  >
                    Pessoa Física
                  </Label>
                </Col>
                <Col lg={6}>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="radioTipo"
                    id="radioCNPJ"
                    defaultValue="cnpj"
                    checked={tipoCliente === "cnpj"}
                    disabled
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="radioCNPJ"
                    style={{ marginLeft: "10px" }}
                  >
                    Pessoa Jurídica
                  </Label>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">
                {tipoCliente === "cpf" ? "Nome Completo" : "Razão Social"}
              </Label>
              <Input
                type="text"
                className="form-control"
                maxLength={255}
                value={nome}
                onChange={formatNome}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">
                {tipoCliente === "cpf" ? "Nascimento" : "Início da Empresa"}
              </Label>
              <Input
                type="date"
                className="form-control"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">E-mail</Label>
              <div className="form-icon">
                <Input
                  type="email"
                  className="form-control form-control-icon"
                  placeholder="examplo@gmail.com"
                  value={email}
                  onChange={formatEmail}
                />
                <i className="ri-mail-unread-line"></i>
              </div>
            </div>
          </Col>
          {dadosCliente.map((item, index) => {
            let mask = "";
            let placeholder = "";
            let disabled = false;

            switch (item.nome) {
              case "Telefone":
                mask = "(99) 9999-9999";
                placeholder = "(00) 0000-0000";
                break;
              case "Celular":
                mask = "(99) 99999-9999";
                placeholder = "(00) 00000-0000";
                break;
              case "CPF":
                mask = "999.999.999-99";
                placeholder = "000.000.000-00";
                disabled = true;
                break;
              case "RG":
                mask = "99.999.999-9";
                placeholder = "00.000.000-0";
                break;
              case "CNPJ":
                mask = "99.999.999/9999-99";
                placeholder = "00.000.000/0000-00";
                disabled = true;
                break;
              case "Inscrição Estadual":
                mask = "999.999.999.999";
                placeholder = "000.000.000.000";
                break;
              default:
                break;
            }

            if (mask !== "")
              return (
                <Col lg={6} key={index}>
                  <div className="mb-3">
                    <Label className="form-label">{item.nome}</Label>
                    {disabled ? (
                      <InputMask
                        mask={mask}
                        placeholder={placeholder}
                        defaultValue={item.value}
                        onFocus={(e) => e.preventDefault()}
                        style={{ pointerEvents: "none", opacity: 0.7 }}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    ) : (
                      <InputMask
                        mask={mask}
                        placeholder={placeholder}
                        value={item.value}
                        onChange={(e) => {
                          const updatedData = [...dadosCliente];
                          updatedData[index].value = e.target.value;
                          setDadosCliente(updatedData);
                        }}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    )}
                  </div>
                </Col>
              );
            else return null;
          })}

          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Revendedor</Label>
              {loading ? (
                <Loader />
              ) : user.data.role === "admin" ? (
                <select
                  className="form-select mb-3"
                  value={revendedor}
                  onChange={(e) => setRevendedor(e.target.value)}
                >
                  {revendas.map((revenda) => (
                    <option key={revenda.id} value={revenda.id}>
                      {revenda.name}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className="form-select mb-3"
                  defaultValue={revendedor}
                  disabled
                >
                  {revendas.map((revenda) => (
                    <option key={revenda.id} value={revenda.id}>
                      {revenda.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Status</Label>
              <select
                className="form-select mb-3"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
                <option value="cancelado">Cancelado</option>
                <option value="suspenso">Suspenso</option>
                <option value="aguardando">Aguardando</option>
                <option value="pendente">Pendente</option>
                <option value="bloqueado">Bloqueado</option>
              </select>
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label className="form-label">Endereço de Cobrança</Label>
              <select
                className="form-select mb-3"
                value={enderecoCobranca}
                onChange={(e) => setEnderecoCobranca(e.target.value)}
              >
                {enderecos.map((endereco) => (
                  <option key={endereco.id} value={endereco.id}>
                    {`${endereco.endereco}, ${endereco.numero} - ${endereco.complemento} - ${endereco.bairro}`}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
        <h5 style={{ padding: "20px 10px" }}>Dados de Cobrança</h5>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">Vencimento</Label>
              <select
                className="form-select mb-3"
                value={vencimento}
                onChange={(e) => setVencimento(e.target.value)}
              >
                <option value="10">Dia 10</option>
                <option value="20">Dia 20</option>
                <option value="30">Dia 30</option>
              </select>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailInput" className="form-label">
                Forma de Pagamento
              </Label>
              <select
                className="form-select mb-3"
                value={formaPagamento}
                onChange={(e) => setFormaPagamento(e.target.value)}
              >
                <option value="boleto">Boleto</option>
                <option value="pix">Pix</option>
                <option value="carnet">Carnê</option>
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Cadastrar
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default TabPerfil;
